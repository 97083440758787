<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-label font-weight-bolder text-dark">
              <slot name="title">Create New Users</slot>
              <br />
              <span class="text-muted font-weight-bold font-size-sm mt-1"
                >Invite new users to your company</span
              >
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link to="/users" v-slot="{ href, navigate }">
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="View All Types"
                  @click="navigate"
                >
                  <i class="fas fa-list pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <div class="row">
              <form
                @submit.prevent="submit"
                class="form col-md-8 mx-auto mt-5"
                novalidate="novalidate"
                autocomplete="false"
              >
                <div class="pb-5">
                  <div class="form-group">
                    <label>User's Email</label>
                    <v-combobox
                      v-model="emails"
                      deletable-chips
                      chips
                      clearable
                      filled
                      multiple
                      small-chips
                      solo
                      :rules="emailRules"
                      persistent-hint
                      hint="Invite multiple users by entering an email then hit enter
                      to add more"
                      label="start typing to enter emails"
                    >
                    </v-combobox>
                    <!-- <input
                      class="form-control form-control-solid form-control-lg"
                      type="text"
                      placeholder="Email Address"
                      autocomplete="off"
                      :rules="emailRules"
                      v-model.trim="$v.email.$model"
                    /> -->
                    <template v-if="$v.emails.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.emails.required"
                      >
                        Email(s) required.
                      </span>
                    </template>
                  </div>
                  <!-- <div class="form-group">
                    <label>Action Parties</label>
                    <multiselect
                      v-model="party"
                      :options="actionParties"
                      track-by="name"
                      label="name"
                      :multiple="true"
                      :preserve-search="true"
                      :preselect-first="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Select user action party"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div> -->
                  <div class="form-group">
                    <label>User Role</label>
                    <multiselect
                      v-model="role"
                      :options="roles"
                      track-by="name"
                      label="name"
                      placeholder="Select user role"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>

                <div class="d-flex justify-content-end border-top pt-10 pb-10">
                  <div>
                    <router-link to="/users" v-slot="{ navigate }">
                      <button
                        type="button"
                        class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                        @click="navigate"
                      >
                        Cancel
                      </button>
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                    >
                      <b-spinner
                        v-if="loading == true"
                        variant="light"
                        label="Spinning"
                      ></b-spinner>
                      <span v-if="loading == false">Invite Users</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import {
  SEND_INVITATIONS,
  GET_INVITATIONS
} from "@/core/services/store/signup.module";
import { GET_ROLES } from "@/core/services/store/role.module";

export default {
  props: {
    title: String
  },
  data() {
    return {
      show: true,
      email: "",
      emails: "",
      emailRules: [
        v => {
          if (!v || v.length < 1) return "At least one valid email is required";
          else if (v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (
                !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
                  v[i]
                )
              ) {
                return "An invalid email was entered";
              }
            }
            return true;
          } else {
            return true;
          }
        }
      ],
      role: "",
      loading: false
    };
  },
  validations: {
    emails: { required },
    role: { required }
  },
  components: {
    PilotCard,
    Multiselect
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const bulkEmails = this.emails.toString();
        const invites = bulkEmails.split(",");
        const payload = {
          emails: invites,
          role_id: this.role.id
        };

        this.$store
          .dispatch(SEND_INVITATIONS, payload)
          .then(() => {
            Swal.fire({
              title: "User Invitation",
              text: "Users invitation successfully sent",
              icon: "success",
              heightAuto: false
            });

            this.emails = "";
            this.role = null;
            this.loading = false;
            this.$nextTick(() => {
              this.$v.$reset();
            });

            this.$store.dispatch(GET_INVITATIONS);
          })
          .catch(error => {
            Swal.fire({
              title: "An error occured",
              text: error.data.message,
              icon: "error",
              heightAuto: false
            });
            this.loading = false;
          });
      }
    },
    removeChip(item) {
      alert(item);
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    actionParties() {
      return this.$store.state.actionParty.actionParties;
    },
    roles() {
      return this.$store.state.roles.roles;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: "users" },
      { title: "Invite Users" }
    ]);

    this.$store.dispatch(GET_ROLES);
  }
};
</script>
